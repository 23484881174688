import { FC, useEffect, useMemo } from 'react';
import { useField, utils } from 'informed';
import tinygradient from 'tinygradient';

import Input from '@mui/material/Input';
import Slider, { sliderClasses } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

import { CommonProps } from '../../layout/LoanForm';
import { FormControlStyled } from '../../components';
import { inputSx } from '../TextField/TextField';
import { FieldMap } from '../../model/FieldMap';
import {useFinsalesContext} from "../../FinsalesContext";

const Root = styled('div', { name: 'funedaAmountCombinedField' })`
  position: relative;
  margin-bottom: 1.2rem;
  grid-area: amount;
  
  ${({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  }) }
  
`;

const SliderPositioner = styled('div', { name: 'funedaSliderPositioner' })(({ theme }) => ({
    position: 'absolute',

    bottom: '-2rem',

    ['@media (pointer: coarse)']: {
        bottom: '-2.7rem',
    },

    // top: '2.8rem',
    width: '100%',

    [theme.breakpoints.up('md')]: {
        bottom: '-2.4rem',
    },

    [theme.breakpoints.up('md') + ' and (pointer: coarse)']: {
        bottom: '-2.8rem',
    },

    [theme.breakpoints.up('lg')]: {
        bottom: '-2.4rem',
    },

    [theme.breakpoints.up('lg') + ' and (pointer: coarse)']: {
        bottom: '-2.7rem',
    },

}));


const startC = 'hsl(var(--base-color-h), calc(var(--base-color-s) - 31%), calc(var(--base-color-l) + 14%))';
const finishC = '#DFDFDF';
const sliderTrackCssVar = '--Slider-track-gradient-at';

const gradient = tinygradient(startC, finishC);

const FunedaSlider =  styled(Slider)(({ theme }) => ({
    [sliderTrackCssVar]: startC,

    [`.${sliderClasses.track}`]: {
        border: 0,
        background: `linear-gradient(.25turn, ${startC} 0%, var(--Slider-track-gradient-at) 100%)`
    },
    [`.${sliderClasses.thumb}`]: {
        background: 'var(--Slider-track-gradient-at)',
        width: '1.5rem',
        height: '1.5rem',

        [theme.breakpoints.up('sm')]: {
            width: '2rem',
            height: '2rem',
        },

        [`&:before`]: {
            background: 'hsl(var(--base-color-h), calc(var(--base-color-s) - 4%), calc(var(--base-color-l) - 7%))',
            width: '.9rem',
            height: '.9rem',

            [theme.breakpoints.up('sm')]: {
                width: '1.4rem',
                height: '1.4rem',
            },
        }
    }
}));

const { formatter, parser } = utils.createIntlNumberFormatter('pl-PL', {
    style: 'currency',
    currency: 'PLN'
});

const AmountCombinedField: FC<CommonProps> = props => {
    const {maxAmount} = useFinsalesContext();
    const { t, tabindex } = props;

    const name = 'amount'

    const { fieldState, fieldApi, render, ref } = useField({
        name,
        formatter,
        parser
    })

    // The field state ( contains value, error, dirty.. etc)
    const { value, maskedValue } = fieldState as { value: number, maskedValue: string };

    // The field api ( allows you to control the field )
    const { setValue } = fieldApi;

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    useEffect(() => {
        if (ref?.current) {
            const slider = ref?.current //?.children?.[1];

            if (slider) {
                // track.style.background = `linear-gradient(.25turn, ${startC} 0%, ${gradientAt} 100%)`
                slider.style?.setProperty(
                    sliderTrackCssVar,
                    gradient.rgbAt((value / maxAmount) || 0)
                );
            }

        }
    }, [value, ref]);

    return (
        <Root>
            {render(
                <>
                    <FormControlStyled id="sliderOutput" label={t.basicStep.amount}>
                        <Input
                            name={name}
                            id="sliderOutput"
                            value={maskedValue}
                            readOnly={true}
                            sx={theme => inputSx(theme)}
                            inputProps={{
                                tabIndex: Number('-1')
                            }}
                        />
                    </FormControlStyled>
                    <SliderPositioner>
                        <FunedaSlider
                            ref={ref}
                            min={100}
                            step={100}
                            max={maxAmount}
                            value={Number(value)}
                            onChange={handleChange}
                            aria-label="amount slider"
                            tabIndex={Number(tabindex)}
                        />
                    </SliderPositioner>
                </>
            )}
        </Root>
    )
}

export default AmountCombinedField;
