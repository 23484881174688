import React, { createContext, useContext, ReactNode } from 'react';

// Typowanie danych, które będziesz przekazywać przez kontekst
interface FinsalesContextProps {
    logoText: string;
    maxAmount: number;
    maxPeriod: number;
    flowType: string;
    baseUrl: string;
    agreementsUri: string;
    dataUri: string;
    fallbackUrl: string;
}

const defaultValues: FinsalesContextProps = {
    logoText: '',
    maxAmount: 0,
    maxPeriod: 0,
    flowType: '',
    baseUrl: 'https://finsales-pl.finelf.com',
    agreementsUri: 'agreements/',
    dataUri: 'send_lead/b644f17b-0e76-4863-84e6-24d122314c4b/',
    fallbackUrl: 'https://funeda.pl/wall-of-offers',
};

// Tworzenie kontekstu
const FinsalesContext = createContext<FinsalesContextProps>(defaultValues);

// Typowanie provider-a
interface FinsalesProviderProps {
    children: ReactNode;
    value: FinsalesContextProps;
}

// Tworzenie provider-a, który przekaże wartości do komponentów
export const FinsalesProvider = ({ children, value }: FinsalesProviderProps) => {
        return (
        <FinsalesContext.Provider value={value}>
            {children}
        </FinsalesContext.Provider>
    );
};

// Custom hook, aby łatwo korzystać z kontekstu
export const useFinsalesContext = () => useContext(FinsalesContext);
